import {obtenerEmpleosApi} from "@/model/empleo/empleo.repository";

const obtenerEmpleos = () => {
    return new Promise((resolve, reject) => {
        obtenerEmpleosApi()
            .then((response) => {
                if (response.data.status) {
                    resolve(response.data.obj);
                } else {
                    reject({response: response, error: "Ha habido un problema al obtener los empleos disponibles."})
                }
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export default obtenerEmpleos;