<template>
  <div>


    <v-card-title class="px-1"><p class="mb-0 text-h5">Preguntas frecuentes</p></v-card-title>

    <v-text-field
        class="mx-1"

        placeholder="Ingrese aquí palabras claves de su pregunta"
        prepend-inner-icon="mdi-magnify"
        clearable
        outlined
        dense
        :autofocus="!$vuetify.breakpoint.mobile"
        v-model.trim="busqueda"
    ></v-text-field>
    <v-card-text class="pt-0">
      <v-row justify="center" class="mb-10">
        <v-expansion-panels v-model="panelesAbiertos">
          <v-expansion-panel
              v-for="(data,i) in filtrarPreguntas"
              :key="i"
              :id="data.id"
              :class="{'blue lighten-5': panelesAbiertos === i}"
          >
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <div class=" align-center">
                  <p class="font-weight-regular" :class="{'mb-2': open, 'mb-0': !open}">{{ data.titulo }}</p>
                  <v-fade-transition >


                    <a v-if="open"
                       key="open"
                       :href="'#'+data.id"
                       class="mt-3"
                    >
                      {{ '#' + data.id }}
                    </a>
                  </v-fade-transition>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="mb-0 text-justify" v-html="data.cuerpo"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card-text>

  </div>
</template>

<script>
export default {
  name: "InformacionViewPreguntasFrecuentes",
  data: () => ({
    panelesAbiertos: [],
    busqueda: "",
    dataTYC: [
      {
        id: "faq-1",
        titulo: "¿Cómo me comunico con el Conservador?",
        cuerpo:
            'Desde el comienzo de la pandemia se han potenciado los canales de comunicación digitales, estando actualmente disponibles la sección de Contacto en la pestaña de Información y el correo cotizaciones@cbrls.cl.',
      },
      {
        id: "faq-2",
        titulo: "Para solicitar documentos se me pide ingresar Foja, Número y Año, ¿a qué corresponden estos datos?",
        cuerpo:
            "Toda inscripción realizada en el CBRLS posee un índice único. La Foja corresponde a la hoja del tomo donde inicia la inscripción (está citada en la parte superior del inicio de su inscripción). El Número es un valor correlativo que aumenta de inscripción a inscripción (está ubicado en el epígrafe). El año corresponde al que fue realizado la inscripción, se encuentra en la primera línea del cuerpo del documento.",
      },
      {
        id: "faq-3",
        titulo: "Realicé una compra en el Portal, ¿qué procede?",
        cuerpo:
            "Dentro del horario hábil del Conservador de Bienes Raíces de La Serena se generará una o más carátulas (órdenes de trabajo) dependiendo del número de solicitudes que habían en su modal-pago de compras. Apenas son generadas, por cada una se le enviará un correo informativo para realice el seguimiento del estado de avance de cada una.",
      },
      {
        id: "faq-4",
        titulo: "Realicé una compra, ¿cómo obtengo mis documentos?",
        cuerpo:
            "Desde Septiembre de 2019 (con excepción de los Planos Propiedad y Planos Ley 19.537), todo documento solicitado en este Portal es enviado vía correo con Firma Electrónica. Podrá descargarlos para enviarlos y/o imprimirlos. Debe considerar que para toda solicitud, según sea necesario, nuestros operarios pueden realizar estudios o búsquedas adicionales y es por ello que los plazos de entrega son aproximados.",
      },
      {
        id: "faq-5",
        titulo: "¿Cómo puedo saber mi número de carátula?",
        cuerpo:
            "Si bien se envía un correo desde la dirección no-responder@cbrls.cl cada vez que una es generada, puede saber el número de sus carátulas ingresando a la sección Mi Cuenta y luego a Mis Carátulas.",
      },
      {
        id: "faq-6",
        titulo: "¿Cuánto demora mi trámite?",
        cuerpo:
            "Si bien se muestra una referencia, para cada trámite los plazos mencionados en este portal son aproximados debido a varios factores, entre ellos el estudio o búsquedas adicionales según cada requerimiento.",
      },
      {
        id: "faq-7",
        titulo: "Realicé una compra en horario NO hábil, ¿cuándo tendré mi número de carátula?",
        cuerpo:
            "Para toda compra realizada un sábado, domingo, festivo o después de las 15:50 horas, las carátulas son generadas automáticamente el día hábil siguiente entre las 9:00 y 9:30 de la mañana.",
      },
      {
        id: "faq-8",
        titulo: "Tengo una carátula con saldo por pagar y el sistema NO me permite realizarlo, ¿qué debo hacer?",
        cuerpo:
            "Nuestro sistema bloquea el pago de saldos si su carátula posee documentos en estado pendiente. Apenas nuestros operarios indiquen que todos sus requerimientos están listos para recibir la Firma Electrónica la opción de pago de saldo es liberada.",
      },
      {
        id: "faq-9",
        titulo: "Tengo una carátula con saldo a favor, ¿qué debo hacer?",
        cuerpo:
            "Ingresando a la sección Mi Cuenta y luego Mis Carátulas, podrá completar un formulario con sus datos de cuenta bancaria y solicitar la devolución. Entendiendo que las solicitudes van al departamento de contabilidad y otras áreas del CBRLS, es posible que el pago no sea inmediato.",
      },
      {
        id: "faq-10",
        titulo: "¿Puede otra persona retirar mis documentos en el CBRLS",
        cuerpo:
            "Para toda carátula con retiro de documentos en forma presencial, estos son entregados al requirente o a cualquier persona que posea la colilla entregada al momento del pago en caja. En caso que no posea esta, puede dar autorización a otra persona con una carta poder o enviando un correo a cotizaciones@cbrls.cl.",
      },
      {
        id: "faq-11",
        titulo: "¿Diferencias entre una escritura e inscripción?",
        cuerpo:
            "Con escritura nos referimos al documento firmado en notaría con el cual se da origen a una inscripción en el Conservador. Debe considerar que para todo trámite realizado en este portal (con excepción de los Alzamientos de Prohibiciones y Cancelación de Hipotecas), los datos citados son de la Inscripción y NO de la escritura.",
      },
      {
        id: "faq-12",
        titulo: "¿Qué necesito para inscribir una propiedad?",
        cuerpo:
            "Debe presentar la escritura pública de notario impresa por ambos lados (sin páginas en blanco, con firma original o electrónica) y formulario 2890 del Servicio de Impuestos Internos.",
      },
      {
        id: "faq-13",
        titulo: "¿Qué necesito para inscribir una posesión efectiva?",

        cuerpo:
            "Debe presentar el certificado de posesión efectiva otorgado por el Registro Civil, certificado de impuesto a la herencia (SII) y certificado de avalúo de las propiedades involucradas.",
      },
      {
        id: "faq-14",
        titulo: "¿Cómo se gestiona un alzamiento de Usufructo?",

        cuerpo:
            "Se requiere carta más certificado de defunción, el trámite se realiza de forma presencial.",
      },
      {
        id: "faq-15",
        titulo: "¿Cómo se realiza un alzamiento SERVIU?",
        cuerpo:
            "Es necesario completar un formulario en forma presencial en el CBRLS.",
      },
      {
        id: "faq-16",
        titulo: "Arancel de los Conservadores de Bienes Raíces y Comercio",
        cuerpo:
            "DIARIO OFICIAL 03 DE DICIEMBRE DE 1998 <br/>" +
            "Número 588 exento.- <br/><br/>" +
            "DECRETO: <br/>" +
            "Artículo 1°.- Los derechos de los Conservadores de Bienes Raíces y de Comercio serán los siguientes: <br/><br/>" +
            "1.- a) Por cada inscripción, incluida la anotación en el repertorio, citas de títulos, notas de transferencias o referencias y su certificación en el título, $2.000.-<br/><br/>" +
            "En los Conservadores de Bienes Raíces de Santiago, Valparaíso, Viña del Mar y San Miguel, cobrarán además un recargo calculado sobre el monto del acto o contrato a que se refiere la inscripción, de dos por mil, hasta $128.000.000.-, que será el límite sobre el cual se aplicará este recargo.<br/><br/>" +
            "En los demás Conservadores, el recargo establecido en el inciso anterior se aumentará en un cincuenta por ciento, no pudiendo calcularse sobre montos superiores a $128.000.000.-<br/><br/>" +
            "No procederá este recargo respecto de las inscripciones de promesa de celebrar un acto o contrato, ni de la cancelación, ni de las de modificación de contratos salvo que estas modificaciones importen un aumento de su cuantía, en cuyo caso se aplicará sobre la diferencia y sujeto al mismo límite máximo.<br/><br/>" +
            "Para la aplicación del recargo respecto del contrato de hipoteca con claúsula de garantía general, se considerará monto del contrato el avalúo fiscal de los bienes gravados; en el contrato de prenda con claúsula de garantía general, el monto será el valor asignado a las especies gravadas.<br/><br/>" +
            "La inscripción de títulos de contratos o actos no suceptibles de apreciación pecuniaria, como prohibiciones, embargos, reglamentos de copropiedad, no serán afectos al recargo del inciso 2° y pagarán una tasa única de $3.500.-<br/><br/>" +
            "b) Por las inscripciones ordenadas practicar en conformidad al decreto ley N° 2.695 de 1979, y demás actuaciones que se indican, se aplicará una tasa única sin recargo alguno, de 1/2 Unidad Tributaria. Esta tasa comprenderá, además de la citada inscripción, la agregación del plano y de la resolución que ordena inscribir, al final del Registro de Propiedad; escrituras en la matriz; otorgamiento de cuatro copias autorizadas de la inscripción; subinscripción o anotación al margen de la inscripción, dejando constancia de la prohibición de gravar o enajenar; inscripción de la prohibición y cuatro copias autorizadas de ella; cancelación o alzamiento de la prohibición transcurrido el plazo de un año, y cualquiera otra actuación derivada de la aplicación del procedimiento establecido en el decreto ley anteriormente indicado.<br/><br/>" +
            "En todos los Conservadores, los derechos por cada subinscripción o anotación no comprendidas en los incisos anteriores serán de $1.500.-<br/><br/>" +
            "2) Certificados<br/><br/>" +
            "a) Por un certificado de inscripción o subinscripción, $1.500.-<br/><br/>" +
            "b) Por certificado de gravámenes hasta diez años, $1.500.-, de más de diez años, $2.500.<br/><br/>" +
            "c) Por certificado de prohibiciones, hasta diez años, $1.500.-, de más de diez años, $2.500.<br/><br/>" +
            "d) Otros certificados, $1.500.-<br/><br/>" +
            "3) Por cada anotación presuntiva, incluso su certificación en el título, $2.500.-<br/><br/>" +
            "4.-a) Por la inscripción de un testamento, incluso la anotación en el repertorio y su certificación en un título, $2.500.-<br/><br/>" +
            "b) Por la inscripción de un decreto de posesión efectiva desde su anotación en el repertorio hasta su entrega, $2.000.-<br/><br/>" +
            "c) Por la inscripción especial de herencia, $2.000.-, más la mitad del recargo establecido en el inciso 2° de la letra a) del número 1, calculado sobre el avaluo fiscal de los bienes raíces que comprende la inscripción.<br/><br/>" +
            "Si el monto de la herencia fuera inferior a $320.000.- se cobrará solamente el derecho fijo de $2.000.-<br/><br/>" +
            "Por la reconstitución, inscripción o anotaciones que se efectuén en conformidad a la ley N° 16.665, las tasas fijas que señalan en relación al avalúo fiscal de los bienes raíces, según la escala siguiente:<br/><br/>" +
            "Hasta $15.000.- -> $800.-<br/><br/>" +
            "Más de $15.000.- y hasta $30.000.- -> $1.000.-<br/><br/>" +
            "Más de $30.000.- y hasta $75.000.- -> $1.300.-<br/><br/>" +
            "Más de $75.000.- y hasta $150.000.- -> $1.500.-<br/><br/>" +
            "Sobre $150.000.- -> $2.200.-<br/><br/>" +
            "En los demás casos de reconstitución de los Conservadores de Bienes Raíces y de Comercio solo podrán cobrar una ytasa fija de $1.500.-<br/><br/>" +
            "6) Por la protocolización, agregación o archivo de documentos, $2.000.-<br/><br/>" +
            "7) Los Conservadores de Bienes Raíces y de Comercio cobrarán $300.- por cada página de escritura matriz. Tendrán derecho, además, a cobrar $300.- por cada página de copia de inscripción y de los certificados de gravámenes, prohibiciones y litigios.<br/><br/>" +
            "Por la autorización de la matriz, copias y certificaciones, $500.-<br/><br/>" +
            "Artículo 2°.- Los aranceles que se fijan en el presente decreto son máximos.<br/><br/>" +
            "Artículo 3°.- Los Conservadores de Bienes Raíces y de Comercio deberán, dentro del plazo de 30 días contados desde la publicación de este decreto en el Diario Oficial, fijar en todas las dependencias en que se atiende público un cartel que contenga integramente el arancel, cuyas dimensiones no podrán ser inferiores a 30 cm. de ancho por 60 cm. de largo el que será ubicado en lugares de fácil consulta para el público.<br/><br/>" +
            "Cualquier persona podrá formular reclamos por incumplimiento de los preceptos de este arancel, a las intendencias o gobernaciones respectivas, las cuales, una vez que hayan comprobado la procedencia de la denuncia, deberán comunicarla a los Tribunales de Justicia para los fines a que haya lugar.<br/><br/>" +
            "Así mismo, podrán presentarse dichos reclamos ante los ministros visitadores o jueces, en su caso, que tengan a su cargo la fiscalización del oficio respectivo. <br/><br/>" +
            "Artículo 4°.- En los casos de creación de un nuevo Oficio Conservatorio, este cobrará los derechos y recargos contemplados en el artículo 1° del presente decreto, solo cuando se trate de una nueva inscripción.<br/><br/>" +
            "Tratándose de una inscripción preexistente, los respectivos Conservadores cobrarán estos derechos, sin los recargos correspondientes, cuando la nueva solicitud se origine en un nuevo título referido a aquella y que sea de los señalados en los artículos 52 o 53 del Reglamento del Registro Conservatorio de Bienes Raíces.<br/><br/>" +
            "Artículo 5°.- Derógase el decreto exento N° 75 de 9 de marzo de 1994.<br/><br/>" +
            "Artículo 6°.- El presente arancel comenzarán a regir a contar de la fecha de su publicación en el Diario Oficial.<br/><br/>" +
            "Anótese, comuníquese y publíquese.- Por orden del Presidente de la República, José Antonio Gómez Urrutia, Ministro de Justicia Subrogante.",
      },

    ],
  }),
  mounted() {
    this.openTab(this.$route.hash);
  },
  watch: {
    $route(to) {
      this.openTab(to.hash);
    }
  },

  computed: {
    filtrarPreguntas(){
      console.log()

      let filtered = this.dataTYC;
      if(this.busqueda !== ""){
        filtered = this.dataTYC.filter(f=>{
          return f.titulo.toLowerCase().indexOf(this.busqueda.toLowerCase()) !== -1
        })
      }
      return filtered;
    }
  },
  methods: {
    openTab(hash) {
      const _hash = hash.replace("#", "");
      const index = this.dataTYC.findIndex((element) => {
        return element.id === _hash;
      })

      if (index !== -1) {
        this.panelesAbiertos= index;

        setTimeout(() => {
          this.$vuetify.goTo(hash, {
            easing: "easeOutCubic",
            offset: 150
          });
        }, 500);

      }
    }
  }
};
</script>