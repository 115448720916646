<template>
  <div class="">

    <v-card-title><p class="mb-0 text-h5">Acerca del Conservador</p></v-card-title>

    <v-card-text>
      <v-expansion-panels v-model="panelAbierto">
        <v-expansion-panel
            v-for="(data,i) in items"
            :key="i"
            :id="data.id"
            :class="{'blue lighten-5': panelAbierto === i}"
        >
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <div class=" align-center">
                <p class="font-weight-regular" :class="{'mb-2': open, 'mb-0': !open}">{{ data.titulo }}</p>
                <v-fade-transition>


                  <a v-if="open"
                     key="open"
                     :href="'#'+data.id"
                     class="mt-3"
                  >
                    {{ '#' + data.id }}
                  </a>
                </v-fade-transition>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mb-0 text-justify" v-html="data.cuerpo"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>


  </div>
</template>

<script>
export default {
  name: "InformacionViewAcercaDe",
  data() {
    return {
      panelesAbiertos: [],
      panelAbierto: null,
      items:
          [
            {
              id: "quien-es",
              titulo: "¿Quién es?",
              cuerpo: "El Conservador de Bienes Raíces de La Serena es Don Jaime Morandé Miranda, cédula nacional de identidad 6.536.040-3, " +
                  "quien ejerce el cargo desde el 11 de Enero del año 2005 y se encuentra ubicado en Manuel Antonio Matta Nº 245, La Serena."
            },
            {
              id: "donde-ejerce",
              titulo: '¿Dónde ejerce?',
              cuerpo: 'El Conservador de La Serena ejerce su competencia territorial en las comunas de La Serena y La Higuera de la IV Región de Coquimbo. ' +
                  '<br/><br/>' +
                  'Los Conservadores están definidos, según el artículo 446 del Código Orgánico de Tribunales, como "los ministros de fe encargados de los registros ' +
                  'conservatorios de bienes raíces, de comercio, de minas, de Accionistas de sociedades propiamente mineras, de asociaciones de canalistas, de prenda agraria, ' +
                  'de prenda industrial, especial de prenda y demás que les encomienden las leyes."'
            },
            {
              id: "funciones",
              titulo: "¿Qué otras funciones cumple?",
              cuerpo:
                  "También el Conservador cumple funciones como:\n" +
                  "          <br/>" +
                  "          <br/>" +
                  "          <ul>" +
                  "            <li>Archivero Judicial, le corresponde guardar los expedientes tramitados en los Juzgados de la comuna de La" +
                  "              Serena." +
                  "            </li>" +
                  "            <li>Archivero de Protocolos e Instrumentos Públicos, como tal, le corresponde la custodia de todas las" +
                  "              escrituras realizadas por notarías de la comuna de La Serena." +
                  "            </li>" +
                  "            <li>Secretario de la Junta Electoral de la Circunscripción La Serena.</li>" +
                  "          </ul>" +
                  "          <br/>" +
                  "          En cuanto a Ministro de Fe, el Conservador tiene a su cargo la mantención de todas las inscripciónes,\n" +
                  "          subinscripciónes y anotaciones que digan relación con los registros, que son mantenidas en éste oficio durante\n" +
                  "          un plazo de hasta 80 años:\n" +
                  "          <br/>\n" +
                  "          <br/>\n" +
                  "          <ul>\n" +
                  "            <li>Registro de Propiedad</li>\n" +
                  "            <li>Registro de Hipotecas y Gravámenes</li>\n" +
                  "            <li>Registro de Prohibiciones e Interdicciones</li>\n" +
                  "            <li>Registro de Comercio</li>\n" +
                  "            <li>Registro de Prendas</li>\n" +
                  "            <li>Registro de Propiedad de Aguas</li>\n" +
                  "            <li>Registro de Interdicciones y Prohibición de Aguas</li>\n" +
                  "          </ul>\n" +
                  "          <br/>\n" +
                  "          Los registros de antigüedad superior a los 80 años, son remitidos al Archivo Nacional, el cual esta ubicado\n" +
                  "          Santiago de Chile, calle Miraflores #50."
            }
          ]

    }
  },
  mounted() {
    this.openTab(this.$route.hash);
  },
  watch: {
    $route(to) {
      this.openTab(to.hash);
    }
  },
  methods: {
    openTab(hash) {
      const _hash = hash.replace("#", "");

      let index = -1;

      switch (_hash) {
        case "quien-es":
          index = 0;
          break;
        case "donde-ejerce":
          index = 1;
          break;
        case "funciones":
          index = 2;
          break;
        default:
          index = -1;
      }

      if (index !== -1) {
        this.panelAbierto = index;

        setTimeout(() => {
          this.$vuetify.goTo(hash, {
            easing: "easeOutCubic",
            offset: 150
          });
        }, 500);

      }
    }
  }
}
</script>

<style scoped>

</style>
