<template>
  <div>
    <v-fade-transition mode="out-in">
      <v-row key="cargando" v-if="!cargandoInfo">
        <v-col
            cols="12"
            md="6"
            v-for="(data, index) in dataTrabajo"
            :key="index"
            :id="data.id_oferta_empleo"
        >
          <v-card>
            <v-card-text class="pb-0">
              <div>{{ data.area_trabajo }} · {{ data.fecha_publicacion | moment("DD/MM/YYYY") }}</div>
              <p class="text-h5 text--primary">
                {{ data.empleo }}
              </p>
              <div class="text--primary">
                {{ data.descripcion }}
              </div>
            </v-card-text>

            <v-expand-transition>
              <div key="show" v-if="data.show">
                <v-card-text class="py-0">

                  <div class="pt-3 text-subtitle-1 d-flex align-center">
                    <v-icon size="20" class="mr-1">mdi-account-hard-hat</v-icon> <span>Perfil</span>
                  </div>
                  <div>
                    <ul>
                      <li
                          v-for="(item,index) in data.perfil"
                          :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>

                  <div class="pt-3 text-subtitle-1 d-flex align-center">
                    <v-icon size="20" class="mr-1">mdi-hammer-screwdriver</v-icon> <span>Conocimientos y/o manejo en</span>
                  </div>
                  <div>
                    <ul>
                      <li
                          v-for="(item,index) in data.habilidades"
                          :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>

                  <div class="pt-3 text-subtitle-1 d-flex align-center">
                    <v-icon size="20" class="mr-1">mdi-briefcase</v-icon> <span>Lo que ofrecemos</span>
                  </div>
                  <div>
                    <ul>
                      <li
                          v-for="(item,index) in data.ofertas"
                          :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>

                  <div class="pt-3 text-subtitle-1 d-flex align-center">
                    <v-icon size="20" class="mr-1">mdi-file-document-edit</v-icon> <span>¿Cómo postulo?</span>
                  </div>
                  <div>
                    <span class="ml-6">{{ data.postulacion }}</span>
                  </div>

                </v-card-text>
              </div>
            </v-expand-transition>

            <v-divider class="mt-6"></v-divider>
            <v-card-actions
                class="d-flex justify-center"
            >
              <v-btn
                  v-if="!data.show"
                  text
                  small
                  :ripple="false"
                  @click="data.show = true"
              >
                <v-icon size="16" left>mdi-magnify</v-icon>
                Ver más información
              </v-btn>
              <v-btn
                  v-else
                  text
                  small
                  :ripple="false"
                  @click="data.show = false"
              >
                <v-icon size="16" left>mdi-chevron-up</v-icon>
                Ocultar
              </v-btn>
            </v-card-actions>


          </v-card>
        </v-col>
      </v-row>

      <v-row key="cargado" v-else>
        <v-col class="d-flex justify-center align-center">
          <v-progress-circular
              indeterminate
              color="primary"
          />
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>

<script>
import empleoEntity from "@/model/empleo/empleo.entity";

export default {
  name: "InformacionViewTrabajaConNosotros",
  data() {
    return {
      dataTrabajo: null,
      cargandoInfo: false,
      error: null,
    }
  },
  created() {
    this.cargandoInfo = true;
    empleoEntity.obtener()
        .then((response) => {
          this.dataTrabajo = response.map((elemento) => {
            elemento.show = false;
            return elemento;
          });
        })
        .catch((error) => {
          this.error = error.mensaje ? error.mensaje : error;
        })
        .finally(() => {
          this.cargandoInfo = false;
        });
  }
}
</script>

<style scoped>

</style>