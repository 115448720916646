<template>
  <div>
    <v-card
        v-if="usuarioActivo"
        class="mt-6"
    >
      <v-fade-transition mode="out-in">
        <div
            v-if="!mensajeEnviado"
            key="formulario"
        >
          <v-card-title>
            Contáctanos
          </v-card-title>
          <v-card-subtitle>
            Si tiene alguna consulta no dude en escribirnos a <b>cotizaciones@cbrls.cl</b> o enviénos un mensaje
            mediante
            el siguiente formulario:
          </v-card-subtitle>
          <v-card-text>
            <v-form @submit.prevent="enviarMensaje()">
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="nombre"
                      :disabled="enviando"
                      label="Ingrese su nombre"
                      placeholder="Ej: Homero Santana"
                      :error-messages="errorNombre"
                      @blur="$v.nombre.$touch"
                      outlined
                      dense
                      :autofocus="!$vuetify.breakpoint.mobile"
                  >

                  </v-text-field>
                  <v-text-field
                      v-model="email"
                      :disabled="enviando"
                      label="Ingrese su correo electrónico"
                      placeholder="Ej: homerjs@gmail.com"
                      :error-messages="errorEmail"
                      @blur="$v.email.$touch"
                      @input="delayTouch($v.email)"
                      outlined
                      dense
                  >

                  </v-text-field>
                  <v-select
                      v-model="asunto"
                      :disabled="enviando"
                      :items="itemsAsunto"
                      label="Seleccione un asunto"
                      placeholder="Ej: Compras web"
                      :error-messages="errorAsunto"
                      outlined
                      dense
                  >

                  </v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-textarea
                      v-model="mensaje"
                      :disabled="enviando"
                      rows="6"
                      outlined
                      dense
                      label="Ingrese su mensaje"
                      placeholder="Ej: Buenas tardes, necesito saber..."
                      :error-messages="errorMensaje"
                      @input="delayTouch($v.mensaje)"
                  >
                  </v-textarea>
                  <div class="d-flex justify-end">
                    <v-btn
                        type="submit"
                        color="primary"
                        small
                        :loading="enviando"
                    >
                      <v-icon size="16" left>mdi-email</v-icon>
                      Enviar mensaje
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </div>
        <div
            v-else-if="!error"
            key="mensaje-success"
        >
          <v-card-text>
            <v-alert
                class="mb-0"
                text
                type="success"
                border="left"
            >
              Su mensaje ha sido enviado con éxito
            </v-alert>
          </v-card-text>
        </div>
        <div
            v-else
            key="mensaje-error"
        >
          <v-card-text>
            <v-alert
                class="mb-0"
                text
                type="error"
                border="left"
            >
              {{ error }}
            </v-alert>
            <div
                class="d-flex justify-center"
            >
              <v-btn
                  @click="volverAIntentarlo()"
                  class="mt-6"
                  color="primary"
                  small
              >
                <v-icon
                    size="16"
                    left
                >mdi-refresh
                </v-icon>
                Volver a intentarlo
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-fade-transition>

    </v-card>

    <v-alert
        v-else
        text
        class="mt-6"
        border="left"
        type="info"
    >
      Para contactarse con nosotros debe <a href="#" @click="modalIniciarSesion()">iniciar sesión</a>. En caso que no
      posea una cuenta registrada en nuestro portal
      puede crear una
      <router-link to="/registro">aquí</router-link>
      .
    </v-alert>
  </div>
</template>

<script>
import {required, email, minLength} from 'vuelidate/lib/validators'
import contactoEntity from "@/model/contacto/contacto.entity";
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

const touchMap = new WeakMap();

export default {
  name: "InformacionViewContacto",
  data() {
    return {
      usuarioActivo: false,
      itemsAsunto: [
        {text: "Compras Web", value: 1},
        {text: "Cotizaciones de Inscripciones", value: 3},
        {text: "Otros", value: 999}
      ],
      //
      nombre: null,
      email: null,
      asunto: null,
      mensaje: null,
      enviando: false,
      mensajeEnviado: false,
      error: null,
    }
  },
  created() {
    EventBus.$on("login", (login) => {
      this.usuarioActivo = login;
      console.log("login ", login);
    });
  },
  mounted() {
    usuarioEntity.isSessionActive()
        .then((isActivo) => {
          this.usuarioActivo = isActivo;

        });
  },
  watch: {
    usuarioActivo() {
      this.nombre = usuarioEntity.getNombreUsuario();
      this.email = usuarioEntity.getCorreoUsuario();
    }
  },
  validations: {
    nombre: {required, minLength: minLength(5)},
    email: {required, email},
    asunto: {required},
    mensaje: {required, minLength: minLength(10)},
  },
  computed: {
    errorNombre() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Ingrese su nombre completo");
      !this.$v.nombre.minLength && errors.push("Ingrese su nombre completo");
      return errors;
    },
    errorEmail() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Ingrese su correo electrónico");
      !this.$v.email.email && errors.push("Ingrese un email válido");
      return errors;
    },
    errorAsunto() {
      const errors = [];
      if (!this.$v.asunto.$dirty) return errors;
      !this.$v.asunto.required && errors.push("Seleccione un asunto");
      return errors;
    },
    errorMensaje() {
      const errors = [];
      if (!this.$v.mensaje.$dirty) return errors;
      !this.$v.mensaje.required && errors.push("Escriba el mensaje a enviar");
      !this.$v.mensaje.minLength && errors.push("Escriba un mensaje más largo");
      return errors;
    },
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    async enviarMensaje() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.enviando = true;
      
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      var t = await this.$recaptcha('login');

      contactoEntity.enviarMensaje({
        id_tema: this.asunto.toString(),
        nombre: this.nombre,
        correo: this.email,
        mensaje: this.mensaje
      }, t)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            this.error = error.mensaje ? error.mensaje : error;
          })
          .finally(() => {
            this.mensajeEnviado = true;
            this.enviando = false;
          });

      console.log("mensaje enviado");
    },
    volverAIntentarlo() {
      this.mensajeEnviado = false;
      this.error = null;
    },
    modalIniciarSesion() {
      EventBus.$emit("modal-login", true);
    }
  }
}
</script>

<style scoped>

</style>
