import axios from "axios";

export const obtenerEmpleosApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            "/clientes?action=read-empleos-disponibles"
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}